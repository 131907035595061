import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
type Method = "get" | "post" | "patch" | "put" | "delete"

class Api {
    private readonly _axios: AxiosInstance
    public base: string
    get axios() {
        return this._axios
    }

    constructor() {
        this._axios = axios.create()
        this.updateAdminAuthHeader()
        this.base = `${import.meta.env.VITE_API_BACKEND_URL}/`
    }

    private readonly sendAxiosRequest = async (
        method: Method,
        url: any,
        args?: any,
        config?: AxiosRequestConfig
    ): Promise<any> => {
        const computedUrl = this.getComputedUrl(this.base, url)
        try {
            const response = await this._axios[method](computedUrl, args, config)
            return response.data ?? response
        } catch (err) {
            import.meta.env.MODE !== "test" && console.log(err)
            if ((err as AxiosError).code == "401") {
                window.location.href = "/admin/login"
            }
            throw err
        }
    }
    patch(url: string, args: any): Promise<any> {
        return this.sendAxiosRequest("patch", url, args)
    }
    put(url: string, args: any, config: AxiosRequestConfig) {
        return this.sendAxiosRequest("put", url, args, config)
    }
    post(url: string, args?: any, config?: AxiosRequestConfig): Promise<any> {
        return this.sendAxiosRequest("post", url, args, config)
    }
    get(url: string): Promise<any> {
        return this.sendAxiosRequest("get", url)
    }
    async getFile(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        const computedUrl = this.getComputedUrl(this.base, url)
        return await this._axios.get(computedUrl, config)
    }
    delete(url: string) {
        return this.sendAxiosRequest("delete", url)
    }
    parseCsv(rawCsv: any, callback: any) {
        const stringCsv = typeof rawCsv !== "string" ? rawCsv.join("\n") : rawCsv
        let loop = 0
        let globalKeys: any = []
        const tmpUser: any = []
        import("papaparse").then(Papa => {
            Papa.parse(stringCsv, {
                worker: true,
                step: (results: any) => {
                    if (loop == 0) globalKeys = results.data
                    else {
                        const obj: any = {}
                        results.data.forEach((val: any, index: number) => {
                            const key = globalKeys[index]
                            obj[key] = val
                        })
                        tmpUser.push(obj)
                    }
                    loop++
                },
                complete: () => {
                    typeof callback === "function" && callback(tmpUser)
                }
            })
        })
    }
    getComputedUrl(baseUrl: string, url: string) {
        if (url.startsWith("http")) return url
        while (url.startsWith("/")) {
            url = url.substring(1)
        }
        return `${baseUrl}${url}`
    }
    updateAdminAuthHeader = () => {
        const adminToken =
            localStorage.getItem(import.meta.env.VITE_AUTH_TOKEN_LABEL) ??
            localStorage.getItem(import.meta.env.VITE_AUTH_TOKEN_LABEL_TO_DEPRECATE)
        if (!adminToken) return
        this._axios.interceptors.request.use(
            config => {
                config.headers["Kadmin-Authorization"] = adminToken
                return config
            },
            error => {
                return Promise.reject(new Error(JSON.stringify(error)))
            }
        )
    }
}

export default new Api()
