import { RouteRecordRaw } from "vue-router"
import shareConfig from "@/router/other/shareConfig"
import userMap from "@/router/other/user_map"
import karosEvent from "@/router/other/karosEvent"
import adminUser from "@/router/other/adminUser"
import carpoolPlace from "@/router/other/carpoolPlace"
import kpiDeployment from "@/router/other/kpiDeployment"
import otpCodes from "@/router/other/otpCodes"
import appReleaseEula from "@/router/other/appReleaseEula"
import segment from "@/router/other/segment"
import userSegment from "@/router/other/userSegment"
import userLocations from "@/router/other/userLocations"
import prizes from "@/router/other/prizes"
import cancelMatchesPayment from "@/router/other/cancelMatchesPayment"

export const otherRoutes: RouteRecordRaw[] = [
    ...shareConfig,
    ...userMap,
    ...karosEvent,
    ...adminUser,
    ...carpoolPlace,
    ...kpiDeployment,
    ...otpCodes,
    ...appReleaseEula,
    ...segment,
    ...userSegment,
    ...userLocations,
    ...prizes,
    ...cancelMatchesPayment
]

export default otherRoutes
